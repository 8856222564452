/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useRef, useState } from 'react';
import { Howl } from 'howler';
import LocalStorage, { LocalStorageKey } from '../services/LocalStorage';

const defaultVolume = '100';

type Sound = 'new_customer_message' | 'order';

const spriteStartStop: Record<Sound, [number, number]> = {
    new_customer_message: [0, 400],
    order: [500, 2500],
};
const sprite = Object.fromEntries(
    Object.entries(spriteStartStop).map(([key, [start, stop]]) => [key, [start, stop - start]]),
) as Record<Sound, [number, number]>;

const getLocalVolume = () =>
    parseInt(LocalStorage.getValue(LocalStorageKey.notificationsVolume, defaultVolume), 10);
const setLocalVolume = (volume: number) =>
    LocalStorage.setValue(LocalStorageKey.notificationsVolume, volume.toString());

type UseSoundNotificationsReturn = {
    playNotification: (sound: Sound, skipDebounce?: boolean) => void;
    currentVolume: number;
    setVolume: (value: number) => void;
};

const defaultContext: UseSoundNotificationsReturn = {
    playNotification: () => {},
    currentVolume: getLocalVolume(),
    setVolume: () => {},
};
export const SoundNotificationsContext = createContext(defaultContext);

const audio = new Howl({ src: '/notifications.mp3', sprite, volume: getLocalVolume() / 100 });
const DEFAULT_DEBOUNCE = 1000;
type UseSoundNotificationsParams = {
    debounce?: number;
};
export const useSoundNotifications = ({
    debounce = DEFAULT_DEBOUNCE,
}: UseSoundNotificationsParams): UseSoundNotificationsReturn => {
    const lastPlayTimestamp = useRef(new Date());
    const [currentVolume, setCurrentVolume] = useState(getLocalVolume());

    const setVolume = (newVolume: number) => {
        // newVolume is a number from 1 to 100
        setCurrentVolume(newVolume);
        setLocalVolume(newVolume);
        audio.volume(newVolume / 100);
    };
    const playNotification = (sound: Sound, skipDebounce?: boolean) => {
        // agregamos un debounce para que no se ejecuten muy seguido el sonido
        const now = new Date();
        if (skipDebounce || now.getTime() - lastPlayTimestamp.current.getTime() > debounce) {
            lastPlayTimestamp.current = now;
            audio.play(sound);
        }
    };

    return {
        playNotification,
        currentVolume,
        setVolume,
    };
};
