import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Navbar from './Components/Navbar';
import History from './Views/History/History';
import Dashboard from './Views/Dashboard/Dashboard';
import Kanban from './Views/Orders/Kanban';
import LogIn from './Views/LogIn/LogIn';
import { OrdersContext, useOrders } from './services/Orders';
import { UserContext, useUser } from './services/User';
import { useAppState } from './hooks/useAppState';
import { SoundNotificationsContext } from './hooks/useSoundNotifications';
import { DeliveriesContext, useDeliveries } from './services/Deliveries';
import useNotifications from './hooks/useNotifications';
import { LocalConfigContext, useLocalConfig } from './hooks/useLocalConfig';
import { SchedulerContext, useScheduler } from './hooks/useScheduler';
import InitialChecks from './Components/InitialChecks/InitialChecks';
import { BotmakerChatContext, useBotmakerChat } from './services/BotmakerChat';

export default function App() {
    const { appInitialized, isSignedIn, isLatestVersion, isConnected, sessionId } = useAppState();
    const user = useUser(isSignedIn);
    const { soundNotifications, onOrdersSnapshot } = useNotifications(user.user.id);
    const deliveries = useDeliveries(isSignedIn);
    const localConfig = useLocalConfig();
    const orders = useOrders(user.loading ? undefined : user.user, {
        requestDeliveryAutomatically: localConfig.requestDeliveryAutomatically,
    });
    const scheduler = useScheduler(isSignedIn);
    const chatContext = useBotmakerChat(isSignedIn, orders);

    useEffect(() => {
        if (isSignedIn && appInitialized) {
            onOrdersSnapshot(orders.ordersWithUpdates, orders.lastSnapshotAt);
        }
    }, [
        appInitialized,
        isSignedIn,
        onOrdersSnapshot,
        orders.ordersWithUpdates,
        orders.lastSnapshotAt,
    ]);

    if (!appInitialized) {
        return <LinearProgress />;
    }
    if (isSignedIn) {
        window.clarity('identify', user.user.name, sessionId, 'orders');
        window.clarity('set', 'PharmacyName', user.user.name);
        return (
            <LocalConfigContext.Provider value={localConfig}>
                <SchedulerContext.Provider value={scheduler}>
                    <SoundNotificationsContext.Provider value={soundNotifications}>
                        <UserContext.Provider value={user}>
                            <DeliveriesContext.Provider value={deliveries}>
                                <OrdersContext.Provider value={orders}>
                                    <BotmakerChatContext.Provider value={chatContext}>
                                        <InitialChecks />
                                        <Routes>
                                            <Route
                                                path="/"
                                                element={
                                                    <Navbar
                                                        isLatestVersion={isLatestVersion}
                                                        isConnected={isConnected}
                                                    />
                                                }>
                                                <Route
                                                    path="/"
                                                    element={<Navigate to="/orders" replace />}
                                                />
                                                <Route path="orders" element={<Kanban />} />
                                                <Route path="orders/:id" element={<Kanban />} />
                                                <Route path="dashboard" element={<Dashboard />}>
                                                    <Route
                                                        path="mercadopago-setup"
                                                        element={<Dashboard />}
                                                    />
                                                </Route>
                                                <Route path="historial" element={<History />} />
                                                <Route
                                                    path="login"
                                                    element={<Navigate to="/" replace />}
                                                />
                                                <Route
                                                    path="*"
                                                    element={<Navigate to="/" replace />}
                                                />
                                            </Route>
                                        </Routes>
                                    </BotmakerChatContext.Provider>
                                </OrdersContext.Provider>
                            </DeliveriesContext.Provider>
                        </UserContext.Provider>
                    </SoundNotificationsContext.Provider>
                </SchedulerContext.Provider>
            </LocalConfigContext.Provider>
        );
    }
    return (
        <Routes>
            <Route path="/login" element={<LogIn />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
    );
}
