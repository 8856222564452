import {
    FirestoreDataConverter,
    DocumentData,
    QueryDocumentSnapshot,
    SnapshotOptions,
} from 'firebase/firestore';
import { BotmakerChat } from '../../Models';

export const botmakerChatConverter: FirestoreDataConverter<BotmakerChat> = {
    toFirestore(bmChat: BotmakerChat): DocumentData {
        return bmChat;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): BotmakerChat {
        const bmChat = snapshot.data(options)!;
        return {
            id: snapshot.id,
            customer_id: bmChat.customer_id,
            assigned_pharmacy_id: bmChat.assigned_pharmacy_id,
            customer_username: bmChat.customer_username,
            session_id: bmChat.session_id,
            messages: bmChat.messages?.map((m: any) => ({
                ...m,
                creation_time: m.creation_time.toDate(),
            })),
            session_creation_time: bmChat.session_creation_time.toDate(),
        };
    },
};
