import { createContext, useEffect, useState } from 'react';
import { DisclosedOrder, ID, isSchedulePending, PublicOrder, User, UserOffer } from '../../Models';
import { ordersSubscription, userOffersSubscription } from './subscriptions';
import { toReadyForPickup } from './crud';

const DEFAULT_LAST_SNAPSHOT = new Date();
export type OrdersContextValue = {
    lastSnapshotAt: Date;
    ordersWithUpdates: PublicOrder[];
    emitted: PublicOrder[];
    assigned: DisclosedOrder[];
    approved: DisclosedOrder[];
    loading: boolean;
};
const defaultContext: OrdersContextValue = {
    lastSnapshotAt: DEFAULT_LAST_SNAPSHOT,
    ordersWithUpdates: [] as PublicOrder[],
    emitted: [] as PublicOrder[],
    assigned: [] as DisclosedOrder[],
    approved: [] as DisclosedOrder[],
    loading: true,
};
export const OrdersContext = createContext(defaultContext);

type Config = {
    requestDeliveryAutomatically: boolean;
};
export const useOrders = (user: User | undefined, config: Config): OrdersContextValue => {
    const [userOffers, setUserOffer] = useState(null as null | Record<ID, UserOffer>);
    const [ordersSubscriptionState, setOrdersSubscriptionState] = useState({
        lastSnapshotAt: DEFAULT_LAST_SNAPSHOT,
        ordersWithUpdates: [] as PublicOrder[], // se usa para identificar distintos tipos de eventos
        emitted: [] as PublicOrder[],
        assigned: [] as DisclosedOrder[],
        approved: [] as DisclosedOrder[],
    });
    const [editedOrders, setEditedOrders] = useState({
        emitted: [] as PublicOrder[],
        assigned: [] as DisclosedOrder[],
        approved: [] as DisclosedOrder[],
    });

    useEffect(() => {
        if (!user) return;
        const unsub1 = userOffersSubscription((offers) => {
            setUserOffer(offers);
        });
        const unsub2 = ordersSubscription(user.health_providers, user.delivery_zones, (orders) => {
            if (
                !orders.emittedOrders.length &&
                !orders.assignedOrders.length &&
                !orders.approvedOrders.length
            ) {
                return;
            }
            setOrdersSubscriptionState({
                lastSnapshotAt: new Date(),
                ordersWithUpdates: orders.ordersWithUpdates,
                emitted: orders.emittedOrders,
                assigned: orders.assignedOrders,
                approved: orders.approvedOrders,
            });
            Array.from(
                new Set(
                    [...orders.emittedOrders, ...orders.assignedOrders, ...orders.approvedOrders]
                        .map((o) => o.customer_id)
                        .sort(),
                ),
            );
        });
        // eslint-disable-next-line consistent-return
        return () => {
            unsub1();
            unsub2();
        };
    }, [user]);

    useEffect(() => {
        if (!user) {
            return;
        }
        if (userOffers) {
            const setOffer = <T extends PublicOrder | DisclosedOrder>(order: T) => {
                const newOrder: T = { ...order };
                if (newOrder.id in userOffers) {
                    // eslint-disable-next-line no-param-reassign
                    newOrder.user_offer = userOffers[newOrder.id];
                } else {
                    // Podría ocurrir que una oferta previa sea eliminada, entonces la sacamos del campo
                    // eslint-disable-next-line no-param-reassign
                    newOrder.user_offer = undefined;
                }
                return newOrder;
            };
            setEditedOrders({
                emitted: ordersSubscriptionState.emitted.map(setOffer),
                assigned: ordersSubscriptionState.assigned.map(setOffer),
                approved: ordersSubscriptionState.approved.map(setOffer),
            });
        }
    }, [user, ordersSubscriptionState, userOffers]);

    if (user && config.requestDeliveryAutomatically) {
        editedOrders.approved
            .filter(
                (order) =>
                    order.state === 'approved' && order.user_offer && !isSchedulePending(order),
            )
            .forEach((order) => toReadyForPickup(order));
    }

    return {
        lastSnapshotAt: ordersSubscriptionState.lastSnapshotAt,
        ordersWithUpdates: ordersSubscriptionState.ordersWithUpdates,
        emitted: editedOrders.emitted,
        assigned: editedOrders.assigned,
        approved: editedOrders.approved,
        loading: ordersSubscriptionState.lastSnapshotAt === DEFAULT_LAST_SNAPSHOT,
    };
};
