import { fetchAndActivate, getRemoteConfig, getString } from 'firebase/remote-config';
import { app } from './index';

// la app es de uso masivo. Por ahora vamos con 30 minutos

const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 30 * 60 * 1000;
export const constActivateRemoteConfig = () => fetchAndActivate(remoteConfig);

export const getConfigArray = (key: string, defaultValue: string[]): string[] => {
    const strValue = getString(remoteConfig, key);
    try {
        return JSON.parse(strValue);
    } catch {
        return defaultValue;
    }
};
