import React, { useContext, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { OrderSection } from '../OrderSection';
import { BotmakerChatMessages } from '../../../../../Models';
import { BotmakerChatContext } from '../../../../../services/BotmakerChat';
import { splitUrlsFromText } from '../../../../../utils/utils';

const BACKGROUND_COLOR = '#ece5dd';

function ChatBubble({ message }: { message: BotmakerChatMessages }) {
    let msgText = message.content.text || message.content.selected_button || '';
    if (!msgText) {
        if (message.content.media?.url) {
            msgText = message.content.media?.url;
            if (
                message.content.media?.caption &&
                !message.content.media.caption.includes('.pdf') &&
                !msgText.includes(message.content.media.caption)
            ) {
                msgText += `\n\n${message.content.media?.caption}`;
            }
        }
    }
    const msgTime = dayjs(message.creation_time).format('HH:mm');
    return (
        <Stack
            id={message.id}
            key={message.id}
            direction="row"
            justifyContent={message.from === 'user' ? 'flex-start' : 'flex-end'}>
            <Box
                maxWidth="70%"
                sx={{
                    border: 0,
                    borderRadius: 2,
                    backgroundColor: message.from === 'user' ? 'white' : '#dcf8c6',
                }}
                mt={0.5}
                px={1.2}
                py={0.5}>
                <Typography
                    key="text"
                    variant="body1"
                    style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
                    textOverflow="break-word">
                    {splitUrlsFromText(msgText).map((p) =>
                        p.text ? (
                            p.text
                        ) : (
                            <a key={p.url} href={p.url} target="_blank" rel="noopener noreferrer">
                                {p.url}
                            </a>
                        ),
                    )}
                </Typography>
                <Box key="time" display="flex" width="100%" justifyItems="flex-end" pl={3}>
                    <Typography fontSize={10} width="100%" variant="body2" textAlign="right">
                        {msgTime}
                    </Typography>
                </Box>
            </Box>
        </Stack>
    );
}

const DEFAULT_TOOLTIP = `Enter = enviar.\nShift + Enter = nueva línea`;
const CAPSLOCK_TOOLTIP = 'Bloq Mayús prendido';

function ChatBox() {
    const chatContainerRef = useRef<HTMLDivElement | null>(null);
    const dummyRef = useRef<HTMLDivElement>();
    const { chat, sendMsg, markAsRead } = useContext(BotmakerChatContext);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const previousLastMessageId = useRef('');
    const [typedMessage, setTypedMessage] = useState('');
    const [capsLock, setCapsLock] = useState(false);
    const [isComposing, setIsComposing] = useState(false); // Track composition for accented chars

    useEffect(() => {
        if (!chat?.messages) {
            return;
        }
        const currentLastMsg = chat.messages[chat.messages.length - 1];
        // Marcamos la conversación como leida
        markAsRead();
        if (previousLastMessageId.current === '') {
            // En caso de ser la primera carga de messages, scrolleamos si o si
            dummyRef.current?.scrollIntoView();
        } else if (currentLastMsg.id !== previousLastMessageId.current) {
            if (isInView(previousLastMessageId.current)) {
                scrollToBottom(); // Auto-scroll if already at the bottom
            } else if (currentLastMsg.from === 'user') {
                setShowScrollButton(true); // Notify user if they are not at the bottom
            }
        }
        previousLastMessageId.current = currentLastMsg.id;
    }, [markAsRead, chat?.messages]);

    const scrollToBottom = () => {
        dummyRef.current?.scrollIntoView({ behavior: 'smooth' });
        setShowScrollButton(false);
    };

    const isInView = (id: string) => {
        const elem = document.getElementById(id);
        if (!elem || !chatContainerRef.current) return false;
        const rect = elem.getBoundingClientRect();
        const containerRect = chatContainerRef.current.getBoundingClientRect();
        return rect.top <= containerRect.bottom; // Dummy is visible if its top is within the container
    };

    // Handle user scrolling manually
    const handleScroll = () => {
        if (isInView('dummy')) {
            setShowScrollButton(false);
        }
    };
    const onSubmit = (msg: string) => {
        sendMsg(msg).then(() => setTypedMessage(''));
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        setCapsLock(event.getModifierState('CapsLock'));
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        setCapsLock(event.getModifierState('CapsLock'));

        // Ignore key transformations if Command ⌘ / Ctrl is pressed
        if (event.metaKey || event.ctrlKey) {
            return;
        }

        // Detect Enter to submit the message
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            const submitMsg = typedMessage.trim();
            if (submitMsg) {
                onSubmit(submitMsg);
                setTypedMessage(''); // Clear input
            }
        }
    };

    const handleCompositionStart = () => {
        setIsComposing(true); // Start of special character input
    };

    const handleCompositionEnd = () => {
        setIsComposing(false); // End of special character input
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = event.target;
        const lastChar = value.slice(-1); // Get last character

        // Avoid transforming text during special character input (like accents)
        if (isComposing) {
            if (capsLock && /[A-ZÁÉÍÓÚÜÑ]/g.test(lastChar)) {
                value = value.slice(0, -1) + lastChar.toLowerCase();
            }
            setTypedMessage(value);
            return;
        }

        // Si es la primera letra de un texto vació, la pasamos a mayúscula siempre
        if (value.length === 1) {
            value = value.toUpperCase();
        } else if (capsLock && /[A-ZÁÉÍÓÚÜÑ]/g.test(lastChar)) {
            // If CapsLock is active, but user didn't press Shift, transform last character to lowercase
            value = value.slice(0, -1) + lastChar.toLowerCase();
        }
        setTypedMessage(value);
    };

    return (
        <OrderSection sx={{ m: 0, p: 0, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" alignItems="center" padding={1}>
                <Typography variant="h6" ml={2}>
                    {chat?.customer_username}
                </Typography>
            </Stack>
            <Box
                ref={chatContainerRef}
                onScroll={handleScroll}
                flexGrow={1}
                sx={{
                    backgroundColor: BACKGROUND_COLOR,
                    p: 1.5,
                    overflow: 'hidden',
                    overflowY: 'scroll',
                }}>
                <>
                    {chat?.messages?.map((m) => <ChatBubble message={m} />)}
                    <Box id="dummy" key="dummy" ref={dummyRef} height="1px" />
                    {showScrollButton && (
                        <Button
                            size="small"
                            onClick={scrollToBottom}
                            variant="contained"
                            sx={{
                                alignSelf: 'center',
                                justifySelf: 'center',
                                borderRadius: 5,
                                borderWidth: 1,
                                border: 0,
                                px: 5,
                                position: 'sticky',
                                bottom: '0.5rem',
                            }}
                            endIcon={<ArrowDownward />}>
                            Nuevo mensaje
                        </Button>
                    )}
                </>
            </Box>
            <Stack direction="row">
                <Tooltip
                    placement="top-start"
                    title={
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                            {capsLock ? CAPSLOCK_TOOLTIP : DEFAULT_TOOLTIP}
                        </Typography>
                    }>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder=""
                        multiline
                        disabled={!chat}
                        minRows={1} // Expands dynamically
                        value={typedMessage}
                        style={{
                            backgroundColor: capsLock ? 'lightcoral' : '',
                        }}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onKeyUp={handleKeyUp}
                        onCompositionStart={handleCompositionStart}
                        onCompositionEnd={handleCompositionEnd}
                        sx={{
                            marginTop: 'auto', // Ensures the text input is at the bottom
                        }}
                    />
                </Tooltip>
            </Stack>
        </OrderSection>
    );
}

export { ChatBox };
